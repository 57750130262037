import { _length_range, _width_range } from "__common/constants/modules";
import { isEcoFoot2Plus, isRM10Evolution, isRM10orRM10Evo, isRM5, isRMFamily, isRMGridFlex, isRMIFIProduct, isULA } from "__common/constants/products";
import { state } from "__common/store";
import { VERSION_MAP } from "./version_info";

export const currentProjectVersion = require('local_config').currentProjectVersion

const versionValue = (version: string) : number => {
    if(version) {
        let arr =  version.split('.');
        if(arr.length === 3) {
            return parseInt(arr[2]) + Math.pow(10,3) * parseInt(arr[1]) + Math.pow(10,6) * parseInt(arr[0]);
        }
    }
    return 0;
}


export const latestProjectVersion = (version : string) : boolean => !version || version == currentProjectVersion;

export const greaterThanEqualToProjectVersion = (projectVersion : string, compareVersion: string) : boolean => !projectVersion || (versionValue(projectVersion) >= versionValue(compareVersion));

export const inRangeProjectVersion = (projectVersion : string, lowerVersion: string, higherVersion: string) : boolean => (versionValue(lowerVersion) <= versionValue(projectVersion) && versionValue(projectVersion) < versionValue(higherVersion));

export const needNewZoneClassification = (projectVersion: string): boolean =>  !projectVersion || (versionValue(projectVersion) >= versionValue(VERSION_MAP.roof_zone_and_classfication_changes));

export const removeSMHDrailoptionSMFlush = (projectVersion: string): boolean => !projectVersion || (versionValue(projectVersion) >= versionValue(VERSION_MAP.sm_heavy_rail_option));

export const adjustableTiltSystem = (projectVersion: string): boolean => !projectVersion || (versionValue(projectVersion) >= versionValue(VERSION_MAP.adjustable_tilt_system));

export const baseVersionAvailableForAProduct = (product: string, version: string, dim: string): string => {
    let range, projectVersion, productName;
    dim == 'width' ? range = _width_range : range = _length_range;

    Object.keys(range).some(v => v == product) ? productName = product : productName =  'other';

    version ? projectVersion = version : projectVersion = currentProjectVersion;

    const idx = Object.keys(range[productName]).map(i => versionValue(i)).findIndex(e => e <= versionValue(projectVersion));

    return Object.keys(range[productName])[idx];
}
export const removeSMHDrailoptionULA = (projectVersion: string): boolean => projectVersion === "" || (versionValue(projectVersion) >= versionValue(VERSION_MAP.sm_hd_ula_rail_option));

export const applyRM5SetbackChanges = (projectVersion : string): boolean => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP.rm5_setback_changes);

export const applyRMGF5SetbackChanges = (projectVersion : string): boolean => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP.rm_gridflex_5_setback_changes);

export const removeGFT166Rail = (projectVersion: string) => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP.remove_gft_166_rail)

export const removeULA166Rail = (projectVersion: string) => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP.remove_ula_166_rail)

export const applyDeadloadFactorLimitChanges = (projectVersion: string) => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP.deadload_factor_limit_changes)

export const applyPanelRotationFactorChanges = (projectVersion) => {
    const {projectConfiguration: {productId}} = state();
    return  isRM10Evolution(productId) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['panels_rotation_factor_changes_rm_10_evo']) 
}

export const showRotationBasedMaps = (projectVersion) => {
    const {user : {isStaff}, projectConfiguration: {productId}} = state();
    return isStaff && (isRMFamily(productId) || isEcoFoot2Plus(productId)) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['panels_rotation_factor_changes_rm_10_evo']) 
}

export const applyCentralSupportForRM5 = (projectVersion: string) => {
    const {projectConfiguration: {productId}} = state();
    return isRM5(productId) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['rm5_central_support_changes']) 
}

export const applyRM5Revamp = (projectVersion?: string) => {
    const {projectConfiguration: {productId}} = state();
    return isRM5(productId) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['rm5_revamp']) 
}
export const applyAttachmentCapacityChanges = (productId, projectVersion) => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['rm10_rm10evo_rm5_attachment_capacity_changes']) 

export const checkforBeforeRM5Revamp = (projectVersion?: string) => {
    const {projectConfiguration: {productId}} = state();
    return isRM5(productId) && !greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['rm5_revamp']) 
}

export const applyShortestBuildingLength = (projectVersion: string) => {
    const {projectConfiguration: {productId}} = state();
    return isRMFamily(productId) && !isRMIFIProduct(productId) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['shortest_building_length_changes']) 
}

export const applyEcoFoot2PlusRM10andEvoSetbackChanges = (productId: number, projectVersion : string): boolean => {
    if(isRM10orRM10Evo(productId) || isEcoFoot2Plus(productId)){
        return greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP.rm10_rm10evo_ecofoot2plus_minimum_setback_validation_and_setback_factor_changes)
    } else if(isRMGridFlex(productId)){
        return greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP.rm_gridflex_5_setback_changes)
    } else if(isRM5(productId)) {
        return greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP.rm5_setback_changes)
    } else {
        return false
    }
}

export const applyRemoveMRI25ForEF = (projectVersion?: string) => {
    return greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['removed_25_yrs_mri_option_in_ef2+']);
}

export const applyKrinnerGroundScrewULA = (projectVersion?: string) => {
    const {projectConfiguration: {productId}} = state();
    return isULA(productId) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['ula_krinner_new_part_change']);
}

export const applyMlpeComboClampRailClampChanges = (projectVersion?: string) => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['mple_combo_clamp_rail_clamp_part_changes'])

export const applyModuleLengthRestrictTo97InchForGFT = (projectVersion: string) => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['module_length_restrict_to_97inch_for_gft'])

export const applyMinSetbackValidationBasedOnSeismicDesignCategory = (projectVersion?: string) => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['min_setback_validation_based_on_seismic_design_category']);


export const applyRemovalOfOldTopChord = (projectVersion?: string) => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['gft_removal_of_old_top_chord_option_and_modified_snow_load_calcs']);

export const applyRemovalOfUpslopeRail = (projectVersion?: string) => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['remove_upslope_rail_direction_for_flashloc_duo']);

export const applyAdd185InchRail = (projectVersion?: string) => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['add_185_inch_rail_sm_standard_and_ascender_rail_update']);
export const apply96and185InchRail = (projectVersion?: string) => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['add_96_and_185_inch_rail_nxt']);