const _ = require('lodash');
import Loader from '__common/components/LoaderComponent';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from 'react-md/lib/Buttons/Button';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import { Tab, Tabs, TabsContainer } from 'react-md';
import { connect } from 'react-redux';
import { dispatch } from '__common/store';
import {
  HIDE_REPORT_PROBLEM_FORM,
  RESET_PROBLEM_FORM,
  SEND_REPORT_PROBLEM_FORM,
  SET_PROBLEM_FORM_DATA,
  CLEAR_REPORT_PROBLEM_ERRORS,
  ERROR_SEND_REPORT_PROBLEM_FORM,
  SHOW_IMAGE,
  SELECT_TYPE_OF_CONTACT_SUPPORT,
  SHOW_REPORT_PROBLEM_FORM,
  } from './ReportProblemActions';
import { getAllowedProductsInfo, isUniracOrNuevoSolUser } from '../HeaderComponent/utils/getProducts';
import MultiSelect from 'projectDesign/components/projectConfiguration/components/components/MultiSelect';
import Modal from 'react-bootstrap/Modal';
import SVG from 'react-inlinesvg';
import PreviewImageComponent from '../PreviewImageComponent';
import { showSuccessAlert } from '__common/modules/alerts';
import { reportIssue } from './ReportProblemEpics';
import { openReportProblem } from './ReportProblemButton';
import { TypeOfSupport } from './constants';
import { reportProblemState } from '../../../../../decls/state/reportProblemState';
export const getFormattedWord = (w: string) => w.split('_').join(' ').toUpperCase();
export const getFormattedWordForProductLabel = (w: string) => w.split('-').join('').toUpperCase();

type Props = {
  user: userState,
  dispatch: Function,
  reportProblem: reportProblemState,
};

class ReportProblemForm extends React.Component<Props> {
  recaptchaRef: any = React.createRef();

  componentDidUpdate(prevProps: Props) {
    const { reportProblem: { sending, errors, success } } = this.props;

    if (prevProps.reportProblem.sending && sending && success && Object.keys(errors).length && this.recaptchaRef && this.recaptchaRef.current) {
      this.recaptchaRef.current.reset();
    }

  }

  error = (field: string) => {
    const { reportProblem: { errors } } = this.props;

    if (errors && Object.keys(errors).length && errors[field]) {
      return (
        <div className="input-warning pt-2">
          {errors[field]}
        </div>
      );
    }

    return null;
  }

  iconForReportIssue = (contact_support_category: TypeOfSupport) =>{
    return contact_support_category === TypeOfSupport.ISSUE_REPORT ? require('assets/media/icons/Icon metro-flag-white.svg') : require('assets/media/icons/Icon metro-flag-blue.svg');
  }

  iconForRequestQuote = (contact_support_category: TypeOfSupport) =>{
    return contact_support_category !== TypeOfSupport.ISSUE_REPORT ? require('assets/media/icons/Icon awesome-hands-helping-white.svg') : require('assets/media/icons/Icon awesome-hands-helping-blue.svg');
  }

  cancel = () => {
    const { dispatch } = this.props;
    dispatch(RESET_PROBLEM_FORM());
  }

  validateFormField(field, errorMessage) {
    const { dispatch, reportProblem: { formData } } = this.props;
    if (!formData[field] || formData[field].length === 0) {
      return dispatch(ERROR_SEND_REPORT_PROBLEM_FORM({
        response: {
          [field]: errorMessage,
        },
      }));
    }
  }

  submit = () => {
    const { dispatch, reportProblem: { sending, errors, formData: { captcha, description }, contact_support_category }, user } = this.props;

    if (Object.keys(errors).length) {
      dispatch(CLEAR_REPORT_PROBLEM_ERRORS());
    }

    const fieldAndErrors = [
      ... contact_support_category === TypeOfSupport.ISSUE_REPORT ? [{field: 'captcha', error: 'Please Verify Captcha'}] : [],
      {field: 'product_affected', error: 'Product Affected field is required'},
      {field: 'description', error: 'Description field is required'},
      {field: 'email', error: "Please enter Your Email"},
      {field: 'name', error: "Please enter Your Name"},
    ]

    if (!user.isAuthenticated) {
      dispatch(SET_PROBLEM_FORM_DATA('product_affected', null))
    }

    fieldAndErrors.map(({ field, error }) => {
      this.validateFormField(field, error)
    })

    if (
      ((description && description.length && captcha && captcha.length) || contact_support_category !== TypeOfSupport.ISSUE_REPORT)  &&
      !sending && Object.keys(errors).length === 0
    ) {
      const formData = {
        ...this.props.reportProblem.formData,
        product_affected: JSON.stringify(this.props.reportProblem.formData.product_affected),
        contact_support_category: contact_support_category,
      }
      dispatch(SEND_REPORT_PROBLEM_FORM(formData));
    }
  }

  handleEnterPressOnInputs = (e) => {
    if (e.key === 'Enter') {
      this.submit();
    }
  }

  getBase64 = file => {
    return new Promise(resolve => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  handleChange = (file: File) => {
    this.getBase64(file)
      .then(result => {
        file["base64"] = result;
        file["filename"] = file.name
      })
      .catch(err => {
        console.log(err);
      });
    return file
  };

  handleDeleteImage = (fileName: String, files) => {
    const newFileObj = files.filter((file) => {
      return file.name != fileName
    })
    dispatch(SET_PROBLEM_FORM_DATA('file', newFileObj));
    dispatch(CLEAR_REPORT_PROBLEM_ERRORS());

  }

  displayFileIcon = (file) => {
    if (file.type === "application/pdf") {
      return require('assets/media/icons/PDF_icon.png')
    }
    else if (file.type === "application/zip") {
      return require('assets/media/icons/ZIP_icon.png')
    }
    else {
      return URL.createObjectURL(file)
    }

  }

  produceFileWarningMessages = (file) => {
    let warningMessage = null;
    if (file.type.split("/")[0] === "image") {
      warningMessage = "Image file should be less than 5 MB";
    }
    else if (file.type.split("/")[1] === "pdf") {
      warningMessage = "PDF file should be less than 10 MB";
    }
    else if (file.type.split("/")[1] === "zip") {
      warningMessage = "Zip file should be less than 10 MB";
    }
    dispatch(ERROR_SEND_REPORT_PROBLEM_FORM({
      response: {
        ['file']: warningMessage,
      }
    }))
  }

  fileExists = (file, files) => {
    return files.some(function (f) {
      return f.name === file.name;
    });
  }

  handleChanges = (file, files) => {
    if (!files) {
      files = [];
    }

    const lengthOfFileAtOnce = file.length;
    const lengthOfFiles = files.length;
    const maxNumberOfFiles = 5;
    if (lengthOfFiles + lengthOfFileAtOnce > maxNumberOfFiles) {
      dispatch(ERROR_SEND_REPORT_PROBLEM_FORM({
        response: {
          ['file']: `Maximum number of allowable file uploads has been exceeded. Please select upto ${maxNumberOfFiles} files.`,
        }
      }))
    }
    for (let y = 0; (y < lengthOfFileAtOnce) && (lengthOfFiles + lengthOfFileAtOnce <= 5); y++) {
      const fileSizeInMb = file[y].size / (1000 ** 2);
      if ((fileSizeInMb) > 5 || (fileSizeInMb) > 10) {
        this.produceFileWarningMessages(file[y]);
      }
      else if (this.fileExists(file[y], files)) {
        dispatch(ERROR_SEND_REPORT_PROBLEM_FORM({
          response: {
            ['file']: 'File already exists in the attachment list',
          }
        }))
      }
      else {
        let new_file = this.handleChange(file[y])
        files.push(new_file);
        dispatch(CLEAR_REPORT_PROBLEM_ERRORS());
      }
    }
    dispatch(SET_PROBLEM_FORM_DATA('file', files));
  }

  renderImages = (file) => {
    const renderedImages = file.map((f: File, i) => {
      return (
        <div className="image-info" key={file.name} >
          <Button className="img-delete" onClick={() => this.handleDeleteImage(f.name, file)}>
            <SVG src={require('assets/media/icons/icon_cross.svg')} />
          </Button>
          <img src={this.displayFileIcon(f)} onClick={() => dispatch(SHOW_IMAGE(true, f.name))} />
          <p>{f.name}</p>
        </div>
      )

    })
    return renderedImages;
  }

  classForReportIssueButton(contact_support_category: TypeOfSupport){
    return contact_support_category === TypeOfSupport.ISSUE_REPORT ? 'report-issue-button active' : 'report-issue-button';
  }

  classForRequestForQuoteButton(contact_support_category: TypeOfSupport){
    return contact_support_category !== TypeOfSupport.ISSUE_REPORT ? 'request-quote-button active' : 'request-quote-button inactive';
  }

  classForNeedAccessButton(contact_support_category: TypeOfSupport){
    return contact_support_category === TypeOfSupport.NEED_ACCESS ? 'need-access-button active' : 'need-access-button';
  }

  classForHaveQueryButton(contact_support_category: TypeOfSupport){
    return contact_support_category === TypeOfSupport.HAVE_QUERY ? 'have-query-button active' : 'have-query-button';
  }

  classForHaveSuggestionButton(contact_support_category: TypeOfSupport){
    return contact_support_category === TypeOfSupport.HAVE_SUGGESTION ? 'have-suggestion-button active' : 'have-suggestion-button';
  }

  renderForm = () => {
    const { dispatch, reportProblem: { formData: {email, product_affected, file, qr, project_links, captcha }, contact_support_category }, user } = this.props;
    const renderedImages = file ? this.renderImages(file) : null;   
    const uniqueProductList = getAllowedProductsInfo(user, contact_support_category); 
    const productAffectedControls = uniqueProductList.map(p => {
      if(p.productId === 98){ //helioscope
        return ({value:p.productValue, label:p.productValue});
      }
      else{
        return ({value:p.productValue, label:p.productLabel});
      }
    });
    
    
    return (
      <>
      <div className="report-and-quote-buttons">
        <Button onClick={()=>{
          this.openReportForm();
          }} className={this.classForReportIssueButton(contact_support_category)}><img src={this.iconForReportIssue(contact_support_category)}/>Report an issue</Button>
        <Button onClick={()=>{
          this.openRaiseQuestionsForm()
          }} className={this.classForRequestForQuoteButton(contact_support_category)}><img src={this.iconForRequestQuote(contact_support_category)}/>Raise Questions</Button>
      </div>
      <form id='report-form'>
        {contact_support_category !== TypeOfSupport.ISSUE_REPORT ? (<>
          <p className="select-category-text">Select category to raise request</p>
          <div className="question-category-buttons">
            {user?.isAuthenticated ? 
            <Button onClick={()=>dispatch(SELECT_TYPE_OF_CONTACT_SUPPORT(TypeOfSupport.NEED_ACCESS))} className={this.classForNeedAccessButton(contact_support_category)}>Need Access</Button>
            : null} 
            <Button onClick={()=>dispatch(SELECT_TYPE_OF_CONTACT_SUPPORT(TypeOfSupport.HAVE_QUERY))} className={this.classForHaveQueryButton(contact_support_category)}>Have Query ?</Button>
            <Button onClick={()=>dispatch(SELECT_TYPE_OF_CONTACT_SUPPORT(TypeOfSupport.HAVE_SUGGESTION))} className={this.classForHaveSuggestionButton(contact_support_category)}>Have Suggestion !</Button>
          </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="report_problem_user_name" className="textarea">Customer Name</label>
                  <input type="name" className="form-control" id="report_problem_user_name" placeholder="Customer Name" onChange={(e) => 
                    {
                      dispatch(SET_PROBLEM_FORM_DATA('name', e.target.value))
                      }
                    }/>
                  {this.error('name')}
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="report_problem_user_email" className="textarea">Email</label>
                  <input value={email} type="email" className="form-control" id="report_problem_user_email" onChange={(e) => 
                    {
                      dispatch(SET_PROBLEM_FORM_DATA('email', e.target.value))
                      }
                    }/>
                  {this.error('email')}
                </div>
            </div>
          {user.isAuthenticated? (
              <div className="form-group">
                <label htmlFor="product-affected-select-field" className="textarea">Relevent Products</label>
                <MultiSelect
                  options={productAffectedControls}
                  valArray={product_affected?? []}
                  onChange={(value) => {
                    dispatch(SET_PROBLEM_FORM_DATA('product_affected', value))}
                    }
                />
                {this.error('product_affected')}
                <br /><br />
              </div>
            ): null}
          <div className="form-group">
              <label htmlFor="report_problem_description" className="textarea">Details</label>
              <textarea className="form-control" id="report_problem_description" placeholder="Please enter your message here" rows={5} onChange={(e) => 
                {
                  dispatch(SET_PROBLEM_FORM_DATA('description', e.target.value))
                  }
                }/>
          </div><br /><br /><br /><br /><br /><br />
        </>) :
         (<>
            <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="report_problem_user_name" className="textarea">Customer Name</label>
                  <input type="name" className="form-control" id="report_problem_user_name" placeholder="Customer Name" onChange={(e) => 
                    {
                      dispatch(SET_PROBLEM_FORM_DATA('name', e.target.value))
                      }
                    }/>
                  {this.error('name')}
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="report_problem_user_email" className="textarea">Email</label>
                  <input value={email} type="email" className="form-control" id="report_problem_user_email" onChange={(e) => 
                    {
                      dispatch(SET_PROBLEM_FORM_DATA('email', e.target.value))
                      }
                    }/>
                  {this.error('email')}
                </div>
            </div>

            <div className="form-group">
              <label htmlFor="report_problem_description" className="textarea">{contact_support_category ? `Description of Issue(s)` : `Description`}</label>
              <textarea className="form-control" id="report_problem_description" placeholder="Please enter your message here" rows={5} onChange={(e) => 
                {
                  dispatch(SET_PROBLEM_FORM_DATA('description', e.target.value))
                  }
                }/>
              {this.error('description')}
            </div>

            {user.isAuthenticated? (
              <div className="form-group">
                <label htmlFor="product-affected-select-field" className="textarea">{contact_support_category ? `Products Affected` : `Products`}</label>
                <MultiSelect
                  options={productAffectedControls}
                  valArray={product_affected?? []}
                  onChange={(value) => {
                    dispatch(SET_PROBLEM_FORM_DATA('product_affected', value))}
                    }
                />
                {this.error('product_affected')}
              </div>
            ): null}

          <div className="form-group">
            <label htmlFor="report_problem_project_links" className="textarea">Ubuilder Project Link</label>
            <input className="form-control" id="report_problem_project_links" value={project_links} onChange={(e) => 
              {
                dispatch(SET_PROBLEM_FORM_DATA('project_links', e.target.value))
                }
              }/>
            {this.error('project_links')}
          </div>

          <div className="form-group">
            <label htmlFor="report-problem-file-label" className="textarea mr-3"> Attachments 
              <img className="attachment-icon" src={require('assets/media/icons/attachment_icon.svg')}/>
            </label>
            <input 
              type='file'
              accept="image/*,.jpg,.gif,.png,.jpeg,.pdf,.zip"
              name="avatar"
              id="report-problem-file-label" 
              style={{ display: 'none'}}
              onChange={(e)=>{
                this.handleChanges(e.target.files,file);
                e.target.value=null;
              }}
              multiple/>
              <div className="attachment-note">
                <i>Note: Maximum size of each file for image: 5MB, PDF: 10MB and zip: 10MB</i>
              </div>
              {this.error('file')}
              {<div className="rendered-images">{renderedImages}</div>}
          </div>

          {isUniracOrNuevoSolUser(email) &&
          <>
            {this.error('qr')}
            <div className="form-group">
              <label htmlFor="report_problem_qr" className="textarea">QR</label>
              <input className="form-control col-md-6" id="report_problem_qr" value={qr} ></input>
            </div>
          </>
          }
          
          {!captcha && <ReCAPTCHA
            className="g-recaptcha"
            ref={this.recaptchaRef}
            sitekey="6LczwGkUAAAAANmQA-sCn_AcWFbbxMcmhuZPPE-A"
            onChange={value => {
              dispatch(SET_PROBLEM_FORM_DATA('captcha', value))
              }
            }
          />}
          {this.error('captcha')}
            </>)
          }
      </form>
    </>
    );
  }
openReportForm = () => {
  dispatch(RESET_PROBLEM_FORM())
  dispatch(SELECT_TYPE_OF_CONTACT_SUPPORT(TypeOfSupport.ISSUE_REPORT))
  openReportProblem();
}

openRaiseQuestionsForm = () => {
  const {user} = this.props;
  dispatch(RESET_PROBLEM_FORM())
  const contact_support_category = user.isAuthenticated ? TypeOfSupport.NEED_ACCESS : TypeOfSupport.HAVE_QUERY;
  dispatch(SELECT_TYPE_OF_CONTACT_SUPPORT(contact_support_category));
  openReportProblem();
}

disableSendButton = () => {
  const { reportProblem: { contact_support_category, formData:{name, email, description, product_affected } }, user } = this.props;
  let listOfMandateFields = { name:name, email:email, description:description, product_affected:product_affected }
    
    if(contact_support_category == TypeOfSupport.ISSUE_REPORT && !user?.isAuthenticated) {
      delete listOfMandateFields.product_affected;
    }
    if(contact_support_category !== TypeOfSupport.ISSUE_REPORT) {
      //delete listOfMandateFields.description;
      if(!user?.isAuthenticated){
        delete listOfMandateFields.product_affected;
      }
    }
    const disableSaveButton = Object.values(listOfMandateFields).some(value => {      
     return !value || value?.length === 0;
    });

    return disableSaveButton;
}

  renderAcknowledgement = () => {
    return (
      <>
        <div className="contact-form-acknowledgement">
          <div className="sending-data-text">
            <img className='tick-mark-image' src={require('assets/media/icons/Number.png')} />
            <h1 className='thank-you-text'>Thank you</h1>
            <p className='thank-you-message'>We have received your request & our team is working on it. Our turn around time will be approximate 2 business days.</p>
            <a className='link' >Continue creating projects</a>
            <p className='or-text'>Or</p>
          </div>
          <div className="report-and-quote-buttons">
              <Button onClick={()=>this.openReportForm()} className="report-issue-button in-thankyou-box"><img src={require('assets/media/icons/Icon metro-flag-blue.svg')}/>Report an issue</Button>
              <Button onClick={()=>this.openRaiseQuestionsForm()} className="request-quote-button in-thankyou-box"><img src={require('assets/media/icons/Icon awesome-file-invoice-blue.svg')}/>Raise Questions</Button>
          </div>
        </div>
      </>
    )
  }

  renderContent = () => {
    const { reportProblem: { success, sending, loading } } = this.props;
    if (success) {
      return this.renderAcknowledgement();
    }

    if (sending || loading) {
      return (
        <>
          <div className="issue-report-sending">
            <Loader />
            <div className="sending-data-text">
              Sending data...
            </div>
          </div>
        </>
      );
    }

    return this.renderForm();
  }

  render() {
    const { reportProblem: { open, imageOpen, fileName, sending, success, loading, formData:{file} } } = this.props;
    if(fileName){
      const fileNameSplitArray = fileName.split(".");
      var fileExtension = fileNameSplitArray[fileNameSplitArray.length - 1];
    }
    
    const disableSendButton = this.disableSendButton();
    const actions = [];

    if (!sending && !success && !loading) {
      actions.push(<Button key={1} onClick={this.cancel} className='buttonstyle'>CANCEL</Button>);
      actions.push(<Button key={2} onClick={this.submit} disabled={disableSendButton} className={disableSendButton ? `buttonstyle-disable` : `buttonstyle`}>SEND</Button>);
    }

    return (
      <>
        <Modal show={open} onHide={this.cancel} size="lg"
          {...((sending || loading) ? { style: { height: "700px" } } : null)}
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header>
            <Modal.Title className='titlefont'>
              Support Form
            </Modal.Title>
            <img className='cancel-icon' onClick={this.cancel} src={require('assets/media/icons/Icon material-cancel.png')}/>
          </Modal.Header>
          <Modal.Body>
            {this.renderContent()}
          </Modal.Body>
          {
            !sending && !success && !loading ? 
            (<Modal.Footer>
              {actions}
            </Modal.Footer>)
            : null
          }
          
        </Modal>
        {file && fileName!==null && fileExtension !== "zip" ? <PreviewImageComponent imageOpen={imageOpen} fileName={fileName}/> : null}
      </>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    reportProblem: appState.reportProblem,
    user: appState.user,
  };
}

export default connect(mapStateToProps)(ReportProblemForm);
